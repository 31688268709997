import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { EventDetailsComponent } from './event-details/event-details.component';
import { AppRoutingModule } from './app-routing.module';
import { HomePageComponent } from './home-page/home-page.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { BusinessPageComponent } from './business-page/business-page.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CustomerPageComponent } from './customer-page/customer-page.component';
import { CancelParticipationComponent } from './cancel-participation/cancel-participation.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ImpressumComponent } from './impressum/impressum.component';

@NgModule({
  declarations: [
    AppComponent,
    EventDetailsComponent,
    HomePageComponent,
    FooterComponent,
    BusinessPageComponent,
    ContactUsComponent,
    CustomerPageComponent,
    CancelParticipationComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ImpressumComponent
  ],
  imports: [
    BrowserModule,
    CarouselModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
