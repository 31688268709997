// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true,
  apiBaseUrl: 'https://api.get-clique.com',
  appVersion: 'v8.1.8',


  storeCurrency: [
    { key: 1, value: 'Euro', symbol: '€' },
    { key: 2, value: 'USD', symbol: '$' },
    { key: 3, value: 'INR', symbol: '₹' },
    { key: 4, value: 'Pound', symbol: '£' }
  ]
};