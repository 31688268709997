import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent implements OnInit {
  currentCategory: string = 'Customer'; // Default value is 'Customer'
  createForm;
  constructor() { }

  ngOnInit(): void {
    this.initForm();
  }

  toggleCategory() {
    this.currentCategory = this.currentCategory === 'Business' ? 'Customer' : 'Business';
  }

  initForm() {
    this.createForm = new FormGroup({
      email: new FormControl(null, [Validators.required])
    });
  }

  onSubmit() {
    debugger;
    if (!this.createForm.valid)
      return;
    this.createForm.patchValue({
      email: ''
    });

    Swal.fire({
      // title: `Something Went Wrong!`,
      text: `Thank you for your interest. We will get back to you as soon as possible.`,
      icon: 'success',
    })
  }
}