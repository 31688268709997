<div class="container" id="contact-us">
    <div class="header-line mb-5">
        <h3 class="">Contact Us</h3>
        <p class="mb-0">Any question or remarks? Just write us a message!</p>
    </div>

    <div class="contanct-section">
        <div class="contact-info">
            <div class="heading">
                <h5>Contact Information</h5>
                <label>Say something to start a live chat!</label>
            </div>
            <div class="cont-detl">
                <div>
                    <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 4H2V20H22V4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="#080808" />
                        </svg>
                    </span>
                    hi&#64;get-clique.com
                </div>
                <div>
                    <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12 1.5C9.81276 1.50258 7.71584 2.3726 6.16923 3.91922C4.62261 5.46584 3.75259 7.56276 3.75001 9.75C3.74739 11.5374 4.33124 13.2763 5.41201 14.7C5.41201 14.7 5.63701 14.9963 5.67376 15.039L12 22.5L18.3293 15.0353C18.3623 14.9955 18.588 14.7 18.588 14.7L18.5888 14.6978C19.669 13.2747 20.2526 11.5366 20.25 9.75C20.2474 7.56276 19.3774 5.46584 17.8308 3.91922C16.2842 2.3726 14.1873 1.50258 12 1.5ZM12 12.75C11.4067 12.75 10.8266 12.5741 10.3333 12.2444C9.83995 11.9148 9.45543 11.4462 9.22837 10.8981C9.00131 10.3499 8.9419 9.74667 9.05765 9.16473C9.17341 8.58279 9.45913 8.04824 9.87869 7.62868C10.2982 7.20912 10.8328 6.9234 11.4147 6.80764C11.9967 6.69189 12.5999 6.7513 13.1481 6.97836C13.6962 7.20542 14.1648 7.58994 14.4944 8.08329C14.8241 8.57664 15 9.15666 15 9.75C14.999 10.5453 14.6826 11.3078 14.1202 11.8702C13.5578 12.4326 12.7954 12.749 12 12.75Z"
                                fill="#080808" />
                        </svg>
                    </span>
                    Schmidstrasse 2H, 10179 Berlin, Germany
                </div>
            </div>
            <div class="social-media">
                <a href="#"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 5.5V3.5C9 2.948 9.448 2.5 10 2.5H11V0H9C7.343 0 6 1.343 6 3V5.5H4V8H6V16H9V8H11L12 5.5H9Z"
                            fill="#080808" />
                    </svg>
                </a>
                <a href="https://www.linkedin.com/company/getclique" target="_blank"><svg width="16" height="16" viewBox="0 0 16 16"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M3.5 1.75C3.5 2.7165 2.7165 3.5 1.75 3.5C0.783502 3.5 0 2.7165 0 1.75C0 0.783502 0.783502 0 1.75 0C2.7165 0 3.5 0.783502 3.5 1.75ZM0 5H3.578V16H0V5ZM13.2998 5.12119C13.2954 5.11973 13.2909 5.11825 13.2864 5.11678C13.2616 5.10856 13.2367 5.10033 13.21 5.093C13.162 5.082 13.114 5.073 13.065 5.065C12.875 5.027 12.667 5 12.423 5C10.337 5 9.014 6.517 8.578 7.103V5H5V16H8.578V10C8.578 10 11.282 6.234 12.423 9V16H16V8.577C16 6.915 14.861 5.53 13.324 5.129C13.3159 5.12643 13.3078 5.12382 13.2998 5.12119Z"
                            fill="#080808" />
                    </svg>
                </a>
                <a href="https://x.com/CliqueApp_" target="_blank"><svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16 2.8725C15.405 3.1335 14.771 3.3065 14.11 3.3905C14.79 2.9845 15.309 2.3465 15.553 1.5775C14.919 1.9555 14.219 2.2225 13.473 2.3715C12.871 1.7305 12.013 1.3335 11.077 1.3335C9.261 1.3335 7.799 2.8075 7.799 4.6145C7.799 4.8745 7.821 5.1245 7.875 5.3625C5.148 5.2295 2.735 3.9225 1.114 1.9315C0.831 2.4225 0.665 2.9845 0.665 3.5895C0.665 4.7255 1.25 5.7325 2.122 6.3155C1.595 6.3055 1.078 6.1525 0.64 5.9115C0.64 5.9215 0.64 5.9345 0.64 5.9475C0.64 7.5415 1.777 8.8655 3.268 9.1705C3.001 9.2435 2.71 9.2785 2.408 9.2785C2.198 9.2785 1.986 9.2665 1.787 9.2225C2.212 10.5215 3.418 11.4765 4.852 11.5075C3.736 12.3805 2.319 12.9065 0.785 12.9065C0.516 12.9065 0.258 12.8945 0 12.8615C1.453 13.7985 3.175 14.3335 5.032 14.3335C11.068 14.3335 14.368 9.3335 14.368 4.9995C14.368 4.8545 14.363 4.7145 14.356 4.5755C15.007 4.1135 15.554 3.5365 16 2.8725Z"
                            fill="#080808" />
                    </svg>
                </a>
                <a href="https://youtube.com/@clique_app?feature=shared" target="_blank"><svg width="16" height="16" viewBox="0 0 16 16"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M13.456 2.7585C14.415 2.8125 14.886 2.9545 15.32 3.7265C15.772 4.4975 16 5.8255 16 8.1645V8.1675V8.1725C16 10.5005 15.772 11.8385 15.321 12.6015C14.887 13.3735 14.416 13.5135 13.457 13.5785C12.498 13.6335 10.089 13.6665 8.002 13.6665C5.911 13.6665 3.501 13.6335 2.543 13.5775C1.586 13.5125 1.115 13.3725 0.677 12.6005C0.23 11.8375 0 10.4995 0 8.1715V8.1695V8.1665V8.1635C0 5.8255 0.23 4.4975 0.677 3.7265C1.115 2.9535 1.587 2.8125 2.544 2.7575C3.501 2.6935 5.911 2.6665 8.002 2.6665C10.089 2.6665 12.498 2.6935 13.456 2.7585ZM11 8.1665L6 5.1665V11.1665L11 8.1665Z"
                            fill="#080808" />
                    </svg>
                </a>
                <a href="https://www.instagram.com/getclique_official/" target="_blank"><svg width="16" height="16" viewBox="0 0 16 16"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M5 0H11C13.761 0 16 2.239 16 5V11C16 13.761 13.761 16 11 16H5C2.239 16 0 13.761 0 11V5C0 2.239 2.239 0 5 0ZM11 14.5C12.93 14.5 14.5 12.93 14.5 11V5C14.5 3.07 12.93 1.5 11 1.5H5C3.07 1.5 1.5 3.07 1.5 5V11C1.5 12.93 3.07 14.5 5 14.5H11ZM4 8C4 5.791 5.791 4 8 4C10.209 4 12 5.791 12 8C12 10.209 10.209 12 8 12C5.791 12 4 10.209 4 8ZM5.5 8C5.5 9.378 6.622 10.5 8 10.5C9.378 10.5 10.5 9.378 10.5 8C10.5 6.621 9.378 5.5 8 5.5C6.622 5.5 5.5 6.621 5.5 8ZM12.833 3.7C12.833 3.99437 12.5944 4.233 12.3 4.233C12.0057 4.233 11.767 3.99437 11.767 3.7C11.767 3.40564 12.0057 3.167 12.3 3.167C12.5944 3.167 12.833 3.40564 12.833 3.7Z"
                            fill="#080808" />
                    </svg>
                </a>
            </div>
        </div>

        <div class="cont-text-area">
            <form [formGroup]="createForm" (submit)="onSubmit()">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="custom-field">
                                <label>First Name</label>
                                <input type="text" formControlName="first_name">
                                <p class="text-danger text-left"
                                    *ngIf="isSubmitted && createForm.controls.first_name.hasError('required')">
                                    First Name is required.
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="custom-field">
                                <label>Last Name</label>
                                <input type="text" formControlName="last_name">
                                <p class="text-danger text-left"
                                    *ngIf="isSubmitted && createForm.controls.last_name.hasError('required')">
                                    Last Name is required.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-0 mt-lg-5">
                        <div class="col-sm-12 col-lg-12">
                            <div class="custom-field">
                                <label>Email</label>
                                <input type="text" formControlName="email">
                                <p class="text-danger text-left"
                                    *ngIf="isSubmitted && createForm.controls.email.hasError('required')">
                                    Email Name is required.
                                </p>
                            </div>
                        </div>
                        <!-- <div class="col-sm-12 col-lg-6">
                            <div class="custom-field">
                                <label>Phone Number</label>
                                <input type="text" formControlName="mobile">
                                <p class="text-danger text-left"
                                    *ngIf="isSubmitted && createForm.controls.mobile.hasError('required')">
                                    Phone Number is required.
                                </p>
                            </div>
                        </div> -->
                    </div>

                    <!-- <div class="row mt-0 mt-lg-5">
                        <div class="col-sm-12">
                            <div class="custom-field">
                                <label>Select Subject?</label>
                                <div
                                    class="d-flex flex-column flex-md-row justify-content-between w-100 mt-3 gap-3 gap-md-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="inquiry"
                                            name="inquiry" id="option1" value="General Inquiry 1">
                                        <label class="form-check-label" for="option1">General Inquiry 1</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="inquiry"
                                            name="inquiry" id="option2" value="General Inquiry 2">
                                        <label class="form-check-label" for="option2">General Inquiry 2</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" formControlName="inquiry"
                                            name="inquiry" id="option3" value="General Inquiry 3">
                                        <label class="form-check-label" for="option3">General Inquiry 3</label>
                                    </div>
                                </div>
                                <p class="text-danger text-left"
                                    *ngIf="isSubmitted && createForm.controls['inquiry'].errors?.required">
                                    Subject is required.
                                </p>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-sm-12 mt-0 mt-lg-5">
                        <div class="custom-field">
                            <label>Message</label>
                            <textarea placeholder="Write your message" formControlName="message"></textarea>
                            <p class="text-danger text-left"
                                *ngIf="isSubmitted && createForm.controls.message.hasError('required')">
                                Message is required.
                            </p>
                        </div>
                    </div>

                    <div class="col-sm-12 mt-5">
                        <button class="send-btn" type="submit">Send message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>