<div class="reward-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="text-white">70%
                of your first-time customers never come back. </h3>
            <h3 class="text-white">
                Retain them with Clique.</h3>
            <p class="mb-0">Design personalized rewards and exclusive deals for your customers . Enjoy seamless shopping
                experiences and stay
                updated on upcoming events and promotions tailored just for you.</p>
        </div>
        <div class="img-wrap">
            <div class="box">
                <img src="../assets/logo/image-4.webp" loading="lazy" alt="">
            </div>
            <div class="box">
                <img src="../assets/logo/image-5.webp" loading="lazy" alt="">
            </div>
            <div class="box">
                <img src="../assets/logo/image-6.webp" loading="lazy" alt="">
            </div>
        </div>
    </div>
</div>

<div class="how-it-works-wrapper">
    <div class="container">
        <div class="header-line mb-5">
            <h3>How It Works</h3>
            <p class="mb-0">Start managing your loyalty programs in just three simple steps.</p>
        </div>

        <div class="col-sm-12">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Sign Up as a Business</h3>
                    <p class="mb-0">Sign up through the app and submit for verification.</p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/b1.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b2.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b3.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b1.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b2.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b3.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b1.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b2.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b3.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b1.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b2.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b3.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="first-section overflow-hidden" id="first-section">
                <div class=" col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Choose your engagement strategy</h3>
                    <p class="mb-0">Create loyalty programs, memberships, events & giftcards.</p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/b4.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b5.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b4.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b5.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b4.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b5.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b4.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b5.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Deploy and Get Insights</h3>
                    <p class="mb-0">Deploy the program and get insights on your customers.</p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/b7.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b8.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b9.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b7.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b8.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b9.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b7.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b8.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b9.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b7.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b8.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/b9.jpg" loading="lazy" alt=""
                                    class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="why-business-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="">Why Customers Love Us</h3>
            <p class="mb-0">Empowering store owners to manage loyalty programs with ease and precision.</p>
        </div>
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12 col-lg-6">
                    <div class="b-related">
                        <div class="inner-header-line mb-5 text-center">
                            <h3 class="">Loyalty Programs</h3>
                            <p class="mb-0">Seamlessly deploy cash-backs and reward programs</p>
                        </div>
                        <figure>
                            <img src="../assets/business-img-2.webp" loading="lazy" style="width: 298px;" alt=""
                                class="img-fluid">
                        </figure>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                    <div class="d-flex flex-column h-100">
                        <div class="col-sm-12 h-50">
                            <div class="h--box">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="inner-header-line mb-5">
                                            <h3 class="">Events Management</h3>
                                            <p class="mb-0">Create Events at the store and Manage RSVPs.</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <img src="../assets/business-img-1.svg" alt="" class="img-fluid"
                                            style="margin: auto; display: block;">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 h-50 justify-content-end align-items-end d-flex">
                            <div class="h--box1">
                                <div class="row">
                                    <div class="col-sm-7">
                                        <div class="inner-header-line mb-5">
                                            <h3 class="">Memberships</h3>
                                            <p class="mb-0">Create Memberships for long-term customer engagement.</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <img src="../assets/business-img-3.webp" loading="lazy" alt="" class="img-fluid"
                                            style="margin: auto; display: block;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="why-choose-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="">Why Choose Us?</h3>
            <p class="mb-0">Key Features to Boost Your Business</p>
        </div>

        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12 col-lg-7">
                    <div class="list" *ngFor="let item of businessItems; let i = index" (click)="setActive(i)"
                        [class.active]="activeIndex === i">
                        <label>{{ item.title }}</label>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-5 d-none d-lg-block">
                    <div class="related-to-img">
                        <ul>
                            <li *ngFor="let image of images; let j = index">
                                <img src="{{ image.src }}" alt="" class="image-slide"
                                    [style.display]="activeIndex === j ? 'block' : 'none'" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="our-app-wrap">
        <div class="col-sm-12 d-flex flex-column flex-lg-row">
            <div class="col-sm-12 col-lg-6 c-height d-flex flex-end align-items-end">
                <img src="../assets/business-footer.webp" loading="lazy" class="img-fluid">
            </div>
            <div class="col-sm-12 col-lg-6 left-content">
                <h4>Never lose another customer with Clique</h4>
                <p>Boost customer loyalty with our app. Create personalized rewards, engage customers, and drive repeat
                    visits
                    effortlessly.</p>
                <div class="d-flex flex-row gap-3">
                    <a href="#"><img src="../assets/g-play.svg"></a>
                    <a href="#"><img src="../assets/app-store.svg"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="what-our-user-wrap">
    <div class="header-line mb-5">
        <p class="mb-0">Client Reviews</p>
        <h3 class="">What Our Clients Say About Us</h3>
    </div>
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div> -->

<div class="contact-us-wrap">
    <app-contact-us></app-contact-us>

    <div class="faq-wrap" id="faq">
        <div class="container">
            <div class="header-line mb-5">
                <h3 class="">Frequently asked questions <br>( FAQ )</h3>
            </div>
            <div class="faq-contnt">

                <!-- Accordion Item 1 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            What is Clique, and how does it help my business?
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Clique for Business: Clique is a customer retention app designed for Businesses to keep
                            customers engaged and loyal. We
                            help you reduce churn, increase repeat purchases, and build strong relationships through
                            data-driven insights and
                            automated engagement tools. We help you build a community of loyal consumers around your
                            brand.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 2 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            How does Clique improve customer retention?
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            We analyze customer behavior, identify customers incentives, and automate personalized
                            outreach strategies—-loyalty
                            rewards, and cashback campaigns. By providing your customers incentives to return with our
                            long-term rewards, your
                            customers are more likely to stay engaged with your business.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 3 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Is Clique suitable for my Business?
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes! If you value keeping customers happy and coming back, Clique can help. Clique is widely
                            used across, not limited
                            to,:
                            <ul>
                                <li>
                                    Hospitality: Cafes, Restaurants, Cocktail Bars, Smoothie Bars.
                                </li>
                                <li>
                                    Retail: Fashion, Grocery, Convenience Stores, Pharmacy.
                                </li>
                                <li>
                                    Wellness: Salon, Spa, Crossfit, Sauna, Dance & Fitness Classes.
                                </li>
                                <li>
                                    Entertainment: Game Stores, Theatres, Cinemas, Arcade.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 4 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Do I need technical expertise to use Clique?
                        </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Not at all! Clique is built with simplicity in mind. Our intuitive No-Code app and
                            step-by-step guides make it accessible even if you're not tech-savvy.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 5 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            How quickly can I see results?
                        </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Many of our clients notice improvements in customer engagement within the first few weeks of
                            implementation. Long-term retention benefits grow as you continue to use our insights and
                            tools.
                            <br />
                            <br />
                            To maximize value from your app, follow these key best practices:

                            <ul>
                                <li><b>Create Irresistible Incentives:</b> Ensure your rewards or cashback initiative is
                                    appealing and tailored to customer
                                    preferences. If engagement is low, reassess and make your incentives more
                                    attractive. We are actively adding more
                                    templates to give you flexibility with your programs.</li>
                                <li><b>Increase Visibility:</b> Use stickers, QR codes, and your Branding Kit to promote
                                    the loyalty program in-store. Make sure
                                    customers are aware of the Clique app and its benefits.</li>
                                <li><b>Promote Across Channels:</b> Advertise the program on your website, social media,
                                    and through email newsletters. Use push
                                    notifications to keep customers engaged.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 6 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            How much does Clique cost?
                        </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            We offer flexible pricing plans based on the size of your business. Currently, We are in the
                            Beta Testing Phase during
                            which Clique will be free of cost for the next 3 months.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 7 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            Is my data secure with Clique?
                        </button>
                    </h2>
                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Absolutely! We prioritize data security and comply with industry standards to keep your
                            customer information safe and
                            private.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 8 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                            Can I try Clique before committing?
                        </button>
                    </h2>
                    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes! Clique app is free to use. You only start paying after you have at least 50 users
                            joining
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 9 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                            How do I get started?
                        </button>
                    </h2>
                    <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            It’s simple! Download the App from App Store or Play store to create an account. Our
                            onboarding team will guide you
                            through setup and provide resources to help you succeed.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>