import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from 'src/shared/services/event.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {
  subscription = new Subscription();
  data;

  constructor(
    private _eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getAdminContentMaster();
  }

  private getAdminContentMaster() {
    this.subscription.add(
      this._eventService.getAdminContentMaster(5).subscribe({
        next: (response) => {
          this.data = response.content;
          console.log("[getStoreEventById] response:", response)
        },
        error: (err) => {
          console.log("Error:", err);
        }
      })
    );
  }
}