<div class="reward-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="text-white">7 out
                of 10 people in never use rewards.</h3>
            <h3 class="text-white">Never
                miss another Reward or Cashbacks.</h3>
            <p class="mb-0">Unlock exclusive rewards, special deals, and effortlessly join loyalty programs from
                your
                favorite brands. Enjoy smooth shopping and stay informed about the latest events and promotions, all
                personalized just for you.</p>
        </div>
        <div class="img-wrap">
            <div class="box">
                <img src="../assets/image.webp" loading="lazy" alt="">
            </div>
            <div class="box">
                <img src="../assets/image-1.webp" loading="lazy" alt="">
            </div>
            <div class="box">
                <img src="../assets/image-2.webp" loading="lazy" alt="">
            </div>
        </div>

        <!-- <owl-carousel-o [options]="RewardSlider">
          <ng-template carouselSlide><img src="../assets/logo/image.webp" alt=""></ng-template>  
          <ng-template carouselSlide><img src="../assets/logo/image-1.webp" alt=""></ng-template>  
          <ng-template carouselSlide><img src="../assets/logo/image-2.webp" alt=""></ng-template>  
        </owl-carousel-o> -->
    </div>
</div>

<div class="how-it-works-wrapper">
    <div class="container">
        <div class="header-line mb-5">
            <h3>How It Works</h3>
            <p class="mb-0">Earn and Manage your rewards in just three simple steps.</p>
        </div>

        <div class="col-sm-12">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Join the Loyalty Program</h3>
                    <p class="mb-0">Sign up through the store's app and start earning points with your purchases.
                    </p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/4.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/2.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/3.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/4.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/2.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/3.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/4.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/2.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/3.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/4.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/2.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/3.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="first-section overflow-hidden" id="first-section">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Redeem Your Rewards</h3>
                    <p class="mb-0">Use your rewards to unlock discounts, freebies, and exclusive deals.</p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/4.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/5.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/6.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/4.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/5.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/6.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/4.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/5.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/6.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/4.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/5.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/6.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mt-3">
            <div class="first-section overflow-hidden">
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5">
                    <h3 class="fw-bold">Stay Updated</h3>
                    <p class="mb-0">Be the first to know about upcoming events and special promotions at your
                        favorite stores.
                    </p>
                </div>
                <div class="col-sm-12 col-lg-6 d-flex justify-content-center flex-column px-1 px-lg-5 overflow-hidden">
                    <!-- <owl-carousel-o [options]="RewardSlider3">
            <ng-template carouselSlide><img src="../assets/pro.svg" alt="" class="img-fluid"></ng-template>  
            <ng-template carouselSlide><img src="../assets/pro-1.svg" alt="" class="img-fluid"></ng-template>  
            <ng-template carouselSlide><img src="../assets/pro-2.svg" alt="" class="img-fluid"></ng-template>  
          </owl-carousel-o> -->
                    <div class="Marquee">
                        <div class="Marquee-content marquee-slide">
                            <div class="Marquee-tag"><img src="../assets/7.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/8.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/6.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/7.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/8.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/6.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/7.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/8.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/6.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/7.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/8.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                            <div class="Marquee-tag"><img src="../assets/6.jpg" loading="lazy" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="why-customer-us">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="">Why Customers Love Us</h3>
            <p class="mb-0">Your favourite store is now more rewarding!!</p>
        </div>
        <div class="grid-container">
            <div class="box">
                <h3>Exclusive Rewards</h3>
                <p>Collect and Manage rewards across different brands in place</p>
                <img src="../assets/image1.png" alt="">
            </div>
            <div class="box">
                <h3>Event Alerts</h3>
                <p>Stay updated on special events and promotions from your favorite stores.</p>
                <img src="../assets/image2.png" alt="">
            </div>
            <div class="box">
                <h3>Membership Benefits</h3>
                <p>Access exclusive memberships with additional perks.</p>
                <img src="../assets/image3.png" alt="">
            </div>
            <div class="box">
                <h3>Digital Gift Cards</h3>
                <p>Buy, send, and redeem gift cards instantly.</p>
                <img src="../assets/image4.png" alt="">
            </div>
        </div>
    </div>
</div>

<div class="why-choose-wrap">
    <div class="container">
        <div class="header-line mb-5">
            <h3 class="">Why Choose Us?</h3>
            <p class="mb-0">Key Features to Boost Your Business</p>
        </div>

        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12 col-lg-7">
                    <div class="list" *ngFor="let item of customerItems; let i = index" (click)="setActive(i)"
                        [class.active]="activeIndex === i">
                        <label>{{ item.title }}</label>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-5 d-none d-lg-block">
                    <div class="related-to-img">
                        <ul>
                            <li *ngFor="let image of images; let j = index">
                                <img src="{{ image.src }}" alt="" class="image-slide"
                                    [style.display]="activeIndex === j ? 'block' : 'none'" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="our-app-wrap">
        <div class="col-sm-12 d-flex flex-column flex-lg-row">
            <div class="col-sm-12 col-lg-6 c-height d-flex flex-end align-items-end">
                <img src="../assets/customer-footer.webp" class="img-fluid">
            </div>
            <div class="col-sm-12 col-lg-6 left-content">
                <h4>Start Earning with Clique</h4>
                <p>Transform your shopping experience by earning rewards and staying in the loop with exclusive deals
                    from your favorite
                    stores.</p>
                <div class="d-flex flex-row gap-3">
                    <a href="#"><img src="../assets/g-play.svg"></a>
                    <a href="#"><img src="../assets/app-store.svg"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="what-our-user-wrap">
    <div class="header-line mb-5">
        <p class="mb-0">User Reviews</p>
        <h3 class="">What Our Users Are Saying</h3>
    </div>
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="user-bx">
                <img src="../assets/user-.png">
                <p>This app has completely changed the way I shop! I love earning points and getting rewards from my
                    favorite
                    stores. The exclusive deals and personalized offers make every purchase feel special. It's like
                    having a VIP
                    experience every time I shop</p>
                <div class="dot"></div>
                <div class="name">
                    <strong>Esther Howard</strong>
                    <label>Satisfied Customer</label>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div> -->

<div class="contact-us-wrap">
    <app-contact-us></app-contact-us>

    <div class="faq-wrap" id="faq">
        <div class="container">
            <div class="header-line mb-5">
                <h3 class="">Frequently asked questions <br>( FAQ )</h3>
            </div>
            <div class="faq-contnt">

                <!-- Accordion Item 1 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            What is Clique?
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Clique lets you collect, manage and redeem all the rewards from your favourite stores in one
                            free-to-use app.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 2 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Why should I download Clique?
                        </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Clique gives you access to personalised deals, discounts, rewards and cashbacks from your
                            favourite Businesses.
                        </div>
                    </div>
                </div>

                <!-- Accordion Item 2 -->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Is Clique free to use?
                        </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            Yes, Clique is free for non-business users.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>