<div class="footer-content">
    <div class="container">
        <div class="ft-top">
            <div class="ft-logo">
                <a href="#">
                    <img src="../assets/ft-logo.svg" alt="clique">
                </a>
            </div>
            <div class="app-store">
                <a href="#">
                    <img src="../assets/g-play.svg" alt="">
                </a>
                <a href="#">
                    <img src="../assets/app-store.svg" alt="">
                </a>
            </div>
        </div>

        <div class="ft-link-content">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12 col-lg-4">
                        <div class="about-content">
                            <label>About Us</label>
                            <p>Our app provides an all-in-one solution for store owners to easily manage their
                                loyalty programs,
                                events, memberships, and more. With powerful insights and real-time customer
                                activity tracking, you
                                can grow your business and retain customers effortlessly.</p>
                        </div>
                    </div>

                    <div class="col-sm-4 col-lg-3">
                        <label>Company Information</label>
                        <ul>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Careers</a></li>
                            <li><a href="#contact-us">Contact Us</a></li>
                        </ul>
                    </div>

                    <div class="col-sm-4 col-lg-3">
                        <label>Support Links</label>
                        <ul>
                            <li><a href="impressum">Impressum</a></li>
                            <li><a href="privacy-policy">Privacy Policy</a></li>
                            <li><a href="terms-conditions">Terms of Service</a></li>
                        </ul>
                    </div>


                    <div class="col-sm-4 col-lg-2">
                        <label>Support</label>
                        <ul>
                            <li><a href="https://blog.get-clique.com" target="_blank">Blogs</a></li>
                            <li><a href="#faq">FAQs</a></li>
                            <li><a href="#contact-us">Contact Support</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="ft-bottom">
            <div class="copyright">© 2023 Clique. All Rights Reserved.</div>
            <div class="social-follow">
                <a href="#"><svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9 5.5V3.5C9 2.948 9.448 2.5 10 2.5H11V0H9C7.343 0 6 1.343 6 3V5.5H4V8H6V16H9V8H11L12 5.5H9Z"
                            fill="#080808" />
                    </svg>
                </a>
                <a href="https://www.linkedin.com/company/getclique" target="_blank"><svg width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M3.5 1.75C3.5 2.7165 2.7165 3.5 1.75 3.5C0.783502 3.5 0 2.7165 0 1.75C0 0.783502 0.783502 0 1.75 0C2.7165 0 3.5 0.783502 3.5 1.75ZM0 5H3.578V16H0V5ZM13.2998 5.12119C13.2954 5.11973 13.2909 5.11825 13.2864 5.11678C13.2616 5.10856 13.2367 5.10033 13.21 5.093C13.162 5.082 13.114 5.073 13.065 5.065C12.875 5.027 12.667 5 12.423 5C10.337 5 9.014 6.517 8.578 7.103V5H5V16H8.578V10C8.578 10 11.282 6.234 12.423 9V16H16V8.577C16 6.915 14.861 5.53 13.324 5.129C13.3159 5.12643 13.3078 5.12382 13.2998 5.12119Z"
                            fill="#080808" />
                    </svg>
                </a>
                <a href="https://x.com/CliqueApp_" target="_blank"><svg width="16" height="16" viewBox="0 0 16 16"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16 2.8725C15.405 3.1335 14.771 3.3065 14.11 3.3905C14.79 2.9845 15.309 2.3465 15.553 1.5775C14.919 1.9555 14.219 2.2225 13.473 2.3715C12.871 1.7305 12.013 1.3335 11.077 1.3335C9.261 1.3335 7.799 2.8075 7.799 4.6145C7.799 4.8745 7.821 5.1245 7.875 5.3625C5.148 5.2295 2.735 3.9225 1.114 1.9315C0.831 2.4225 0.665 2.9845 0.665 3.5895C0.665 4.7255 1.25 5.7325 2.122 6.3155C1.595 6.3055 1.078 6.1525 0.64 5.9115C0.64 5.9215 0.64 5.9345 0.64 5.9475C0.64 7.5415 1.777 8.8655 3.268 9.1705C3.001 9.2435 2.71 9.2785 2.408 9.2785C2.198 9.2785 1.986 9.2665 1.787 9.2225C2.212 10.5215 3.418 11.4765 4.852 11.5075C3.736 12.3805 2.319 12.9065 0.785 12.9065C0.516 12.9065 0.258 12.8945 0 12.8615C1.453 13.7985 3.175 14.3335 5.032 14.3335C11.068 14.3335 14.368 9.3335 14.368 4.9995C14.368 4.8545 14.363 4.7145 14.356 4.5755C15.007 4.1135 15.554 3.5365 16 2.8725Z"
                            fill="#080808" />
                    </svg>
                </a>
                <a href="https://youtube.com/@clique_app?feature=shared" target="_blank"><svg width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M13.456 2.7585C14.415 2.8125 14.886 2.9545 15.32 3.7265C15.772 4.4975 16 5.8255 16 8.1645V8.1675V8.1725C16 10.5005 15.772 11.8385 15.321 12.6015C14.887 13.3735 14.416 13.5135 13.457 13.5785C12.498 13.6335 10.089 13.6665 8.002 13.6665C5.911 13.6665 3.501 13.6335 2.543 13.5775C1.586 13.5125 1.115 13.3725 0.677 12.6005C0.23 11.8375 0 10.4995 0 8.1715V8.1695V8.1665V8.1635C0 5.8255 0.23 4.4975 0.677 3.7265C1.115 2.9535 1.587 2.8125 2.544 2.7575C3.501 2.6935 5.911 2.6665 8.002 2.6665C10.089 2.6665 12.498 2.6935 13.456 2.7585ZM11 8.1665L6 5.1665V11.1665L11 8.1665Z"
                            fill="#080808" />
                    </svg>
                </a>
                <a href="https://www.instagram.com/getclique_official/" target="_blank"><svg width="16" height="16"
                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M5 0H11C13.761 0 16 2.239 16 5V11C16 13.761 13.761 16 11 16H5C2.239 16 0 13.761 0 11V5C0 2.239 2.239 0 5 0ZM11 14.5C12.93 14.5 14.5 12.93 14.5 11V5C14.5 3.07 12.93 1.5 11 1.5H5C3.07 1.5 1.5 3.07 1.5 5V11C1.5 12.93 3.07 14.5 5 14.5H11ZM4 8C4 5.791 5.791 4 8 4C10.209 4 12 5.791 12 8C12 10.209 10.209 12 8 12C5.791 12 4 10.209 4 8ZM5.5 8C5.5 9.378 6.622 10.5 8 10.5C9.378 10.5 10.5 9.378 10.5 8C10.5 6.621 9.378 5.5 8 5.5C6.622 5.5 5.5 6.621 5.5 8ZM12.833 3.7C12.833 3.99437 12.5944 4.233 12.3 4.233C12.0057 4.233 11.767 3.99437 11.767 3.7C11.767 3.40564 12.0057 3.167 12.3 3.167C12.5944 3.167 12.833 3.40564 12.833 3.7Z"
                            fill="#080808" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</div>