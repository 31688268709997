import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-customer-page',
  templateUrl: './customer-page.component.html',
  styleUrls: ['./customer-page.component.scss']
})
export class CustomerPageComponent implements OnInit {
  activeIndex: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.activeIndex = 0;
  }

  customerItems = [
    { title: 'Earn Rewards Effortlessly', description: 'Collect points on every purchase and redeem them for exciting deals and discounts.' },
    { title: 'Manage your rewards Seamlessly in one App', description: 'Easily keep track of your rewards from various stores & brands all in one place.' },
    { title: 'Exclusive Offers Just for You', description: 'Get access to special offers, early bird discounts, and personalized rewards based on your preferences.' },
    { title: 'Easy Event Participation', description: 'RSVP to store events, receive updates, and enjoy unique experiences tailored just for loyal customers.' }
  ];

  images = [
    { src: '../../assets/1_green.jpg' },
    { src: '../../assets/2_green.jpg' },
    { src: '../../assets/3_green.jpg' },
    { src: '../../assets/4_green.jpg' }
  ];

  setActive(index: number) {
    this.activeIndex = index;
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      },
      1366: {
        items: 5
      }
    },
    nav: true
  }


}
